import React, {useEffect, useState} from "react";
import {Form, InputGroup} from "react-bootstrap";

export function Input(props) {
  const hash = window.location.hash.substring(1);
  const [url, setUrl] = useState(hash);

  const submit = e => {
    let input = e.target[0].value;
    props.setInput(input);
    e.preventDefault();
  };

  useEffect(()=>{
    props.setInput(hash);
  },[window.location.hash])

  const eventsCount = props.events.length;
  const locatedEventsCount = props.events.filter(event => event.geo !== undefined).length;
  const eventsCountLabel = locatedEventsCount !== eventsCount ? `${locatedEventsCount}/${eventsCount}` : eventsCount;

  return (
    <Form className={'input'} onSubmit={submit}>
      <InputGroup>
        {eventsCount > 0 &&
          <InputGroup.Text>
            {eventsCountLabel} Events
          </InputGroup.Text>
        }
        <Form.Control
          type={'input'}
          value={url}
          onChange={event => setUrl(event.target.value)}
          placeholder={'Link zu Eventim oder Reservix'}
        />
      </InputGroup>
    </Form>
  );
}
