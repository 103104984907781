import {CircleMarker, MapContainer, Marker, Popup, TileLayer, Tooltip} from "react-leaflet";
import 'leaflet/dist/leaflet';

export function Map(props) {
  const position = [51, 9];

  return <MapContainer center={position} zoom={6} className={"map"}>
    <TileLayer
      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    />
    {
      props.events
        .filter(event => event.geo !== undefined)
        .map(event =>
          <CircleMarker center={[event.geo.lat, event.geo.lon]} key={event.url}>
            <Tooltip>{new Date(event.startDate).toLocaleString()}</Tooltip>
          </CircleMarker>
        )
    }
  </MapContainer>;
}