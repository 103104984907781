import {forEach} from "react-bootstrap/ElementChildren";
import {useEffect} from "react";
import * as events from "events";

function fetchEventim(url, setEvents) {
  console.log(`fetching ${url}`);
  const parser = new DOMParser();
  fetch('https://get.mudkips.de', {method: 'POST', body: JSON.stringify({url})})
    .then(res => res.json())
    .then(json => {
      const doc = parser.parseFromString(json.content, "text/html");
      const ldScripts = doc.querySelectorAll('script[type="application/ld+json"]');
      const events = [...ldScripts]
        .map(script => JSON.parse(script.innerHTML))
        .filter(event => event.location !== undefined);
      console.log('setting events', events);
      setEvents(events);
    });
}

function fetchReservix(url, setEvents) {
  console.log(`fetching ${url}`);
  const parser = new DOMParser();
  fetch('https://get.mudkips.de', {method: 'POST', body: JSON.stringify({url})})
    .then(res => res.json())
    .then(json => {
        const doc = parser.parseFromString(json.content, "text/html");
        const ldScripts = doc.querySelectorAll('script[type="application/ld+json"]');
        [...ldScripts]
          .forEach(ldScript => {
              const eventArray = JSON.parse(ldScript.innerHTML);
              if(Array.isArray(eventArray)) {
                setEvents(eventArray);
              }
            }
          );
      }
    )
  ;
}

export function EventFetcher(props) {
  useEffect(() => {
    if (!props.input) return;

    if (props.input.includes('eventim.de')) {
      fetchEventim(props.input, props.setEvents);
      return;
    }

    if (props.input.includes('reservix.de')) {
      fetchReservix(props.input, props.setEvents);
      return;
    }

    console.warn(`${props.input} is not supported`);
  }, [props.input]);

  useEffect(() => {
    const unlocatedEvent = props.events
      .filter(event => event.geo === undefined)
      .find(event => event.location !== undefined);

    if (!unlocatedEvent) return;

    fetch('https://get.mudkips.de/nominatim.php', {
      method: 'POST',
      body: JSON.stringify({address: unlocatedEvent.location.address}),
    })
      .then(res => res.json())
      .then(res => {
        const updatedEvent = unlocatedEvent;
        updatedEvent.geo = res.location.geo;
        props.setEvents(events => {
          return [
            ...events.filter(event => event.url !== updatedEvent.url),
            updatedEvent
          ];
        });
      });
  }, [props.events]);
}