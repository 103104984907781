import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'leaflet/dist/leaflet.css';
import {EventFetcher} from "./EventFetcher";
import {Map} from "./Map";
import {useEffect, useState} from "react";
import {Input} from "./Input";

function App() {
  const [input, setInput] = useState('');
  const [events, setEvents] = useState([]);

  useEffect(() => {
    console.log('events', events);
  }, [events]);

  return (
    <>
      <EventFetcher input={input} events={events} setEvents={setEvents}/>
      <Input events={events} input={input} setInput={setInput}/>
      <Map events={events}/>
    </>
  );
}

export default App;
